import * as constants from "../../constants";
import { filteredListing } from "../../services/attendanceManagement/attendanceManagement.service";

const initialState = {
  activePage: 1,
  searchTeacherListing: [],
  searchString: "",
  limit: 10,
  total: 0,
  classDetails: {},
  activeProfile: {},
  profileSidebarVisibility: false,
  attendaceChart: {},
  checkedItems: new Map(),
  filterSidebarVisibility: false,
  filters: {},
  filteredListing: {},
  attendanceSelector: {},
  monthlyAttendance: {},
};

export const attendanceManagementTeacher = (state = initialState, action) => {
  switch (action.type) {
    case constants.MONTHLY_ATTENDANCE_REPORT_STUDENT: {
      return { ...state, monthlyAttendance: action.payload };
    }
    case constants.SAVE_SEARCH_LISTING_TEACHER: {
      const oldListing = action.payload;
      // oldListing[action.payload.page] = action.payload
      // const activePage = action.payload.page

      return {
        ...state,
        searchListing: oldListing,
        checkedItems: new Map(),
      };
    }
    case constants.SAVE_SEARCH_STRING_TEACHER: {
      return { ...state, searchString: action.payload };
    }
    case constants.SET_LISTING_LIMIT_TEACHER: {
      return { ...state, limit: action.payload };
    }
    case constants.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload,
      };
    case constants.SET_ACTIVE_PROFILE_TEACHER: {
      return {
        ...state,
        activeProfile: action.payload,
      };
    }
    case constants.SET_PROFILE_SIDEBAR_VISIBILITY_TEACHER:
      return {
        ...state,
        profileSidebarVisibility: action.payload,
      };

    case constants.SET_FILTER_SIDEBAR_VISIBILITY_TEACHER:
      return {
        ...state,
        filterSidebarVisibility: action.payload,
      };
    case constants.SET_CHECKED_ITEMS_TEACHER: {
      return {
        ...state,
        checkedItems: action.payload,
      };
    }

    case constants.SET_FILTERS_TEACHER: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case constants.SAVE_ATTENDANCE_CHART_TEACHER: {
      return {
        ...state,
        attendaceChart: action.payload,
      };
    }
    case constants.CLEAR_LISTING_TEACHER: {
      return {
        ...state,
        filteredListing: {},
        activePage: 1,
        searchListing: [],
        checkedItems: new Map(),
        profileSidebarVisibility: false,
        attendaceChart: {},
        filterSidebarVisibility: false,
        activeProfile: {},
        searchListing: [],
        total: 0,
      };
    }
    case constants.SAVE_FILTERED_LISTING_TEACHER: {
      const oldListing = { ...state.filteredListing };
      oldListing[action.payload.page] = action.payload;
      const activePage = action.payload.page;
      const total = action.payload.total_data;

      return {
        ...state,
        filteredListing: oldListing,
        activePage,
        total: total,
      };
    }
    case constants.SET_ATTENDANCE_SELECTOR:
      return {
        ...state,
        attendanceSelector: action.payload,
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        activePage: 1,
        searchTeacherListing: [],
        searchString: "",
        limit: 10,
        total: 0,
        classDetails: {},
        activeProfile: {},
        profileSidebarVisibility: false,
        attendaceChart: {},
        checkedItems: new Map(),
        filterSidebarVisibility: false,
        filters: {},
        filteredListing: {},
        attendanceSelector: {},
        monthlyAttendance: {},
      };
    default: {
      return state;
    }
  }
};
