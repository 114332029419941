import axios from 'axios';

export const bellNotification = () =>{
   let URL =`${process.env.REACT_APP_API_URL}/userNotification/bell-notifications`
   const token = localStorage.getItem("access_token")
   const headers ={
       "content-Type" : "application/json",
       "token": token
   }
   return axios({
       url:URL,
       method: "GET",
       headers: headers
})
.then(response =>{
    
   return response.data
})
}


export const classDetails = () =>{
   let URL =`${process.env.REACT_APP_API_URL}/userClass/class-details`
   const token = localStorage.getItem("access_token")
   const headers ={
       "content-Type" : "application/json",
       "token": token
   }
   return axios({
       url:URL,
       method: "GET",
       headers: headers
})
.then(response =>{
    
   return response.data
})
}

export const markReadBellNotification = (args) =>{
    let URL =`${process.env.REACT_APP_API_URL}/userNotification/read-all-notification`
    const token = localStorage.getItem("access_token")
    const headers ={
        "content-Type" : "application/json",
        "token": token
    }
    return axios({
        url:URL,
        method: "PUT",
        headers: headers,
        data: JSON.stringify(args)
 })
 .then(response =>{
     
    return response.data
 })
 }
 export const fetchStudentDashboardStats = (args) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/student-dashboard`
    const token = localStorage.getItem("access_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers,
        params: {
            month: args.month,
            year: args.year
        }
    })
        .then(response => {
            return response.data
        })
}
export const fetchTeacherDashboardStats = (args) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/teacher-dashboard`
    const token = localStorage.getItem("access_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers
    })
        .then(response => {
            return response.data
        })
}

export const fetchTeacherDashboardGraph = (args) => {
    
    let URL = `${process.env.REACT_APP_API_URL}/RecordedSessions/show-graph-data?month=${args.month}&year=${args.year}`
    const token = localStorage.getItem("access_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers
    })
        .then(response => {
            return response.data
        })
}