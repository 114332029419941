import axios from "axios";

export const login = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/user/login`;
  const payloadlogin = {
    ...payload,
    // "devise_type":"WEB"
  };
  const headers = {
    "content-Type": "application/json",
    device_type: "WEB",
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payloadlogin),
  }).then((response) => {
    return response.data;
  });
};

export const forgotPassword = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/user/forget-password`;
  const payloadlogin = {
    ...payload,
    // "devise_type":"WEB"
  };
  const headers = {
    "content-Type": "application/json",
    device_type: "WEB",
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payloadlogin),
  }).then((response) => {
    return response.data;
  });
};

export const verifyOtpToResetPassword = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/user/verify-otp-to-reset-password`;
  const payloadlogin = {
    ...payload,
    // "devise_type":"WEB"
  };
  const headers = {
    "content-Type": "application/json",
    device_type: "WEB",
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payloadlogin),
  }).then((response) => {
    return response;
  });
};

export const reSendOtpToResetPasswordOnMail = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/user/resend-reset-password-otp`;
  const payloadlogin = {
    ...payload,
    // "devise_type":"WEB"
  };
  const headers = {
    "content-Type": "application/json",
    device_type: "WEB",
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payloadlogin),
  }).then((response) => {
    return response;
  });
};
export const resetPassword = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/user/reset-password`;
  const payloadlogin = {
    ...payload,
    // "devise_type":"WEB"
  };
  const headers = {
    "content-Type": "application/json",
    device_type: "WEB",
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payloadlogin),
  }).then((response) => {
    return response.data;
  });
};

export const uploadProfilePictureToS3 = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/upload`;

  let formData = new FormData();
  formData.append("file", payload);
  return axios.post(URL, formData).then((response) => {
    return response.data;
  });
};

export const updateProfilePicture = (payload) => {
  const token = localStorage.getItem("access_token");

  let URL = `${process.env.REACT_APP_API_URL}/user/update-profile-picture`;
  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payload),
  }).then((response) => {
    return response.data;
  });
};

export const validateToken = async (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/user/verify-token`;
  const payloadlogin = {
    ...payload,
    // "devise_type":"WEB"
  };
  const headers = {
    "content-type": "application/json",
    device_type: "WEB",
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payloadlogin),
  }).then((response) => {
    return response.data;
  });
};

export const fetchProfilePageData = async () => {
  const token = localStorage.getItem("access_token");
  let URL = `${process.env.REACT_APP_API_URL}/user/get-profile`;
  const headers = {
    "content-type": "application/json",
    token: token,
  };
  const response = await axios({
    url: URL,
    method: "GET",
    headers: headers,
  });
  return response.data;
};

export const uploadFacesToS3 = (payload) => {
  let URL = `${process.env.REACT_APP_API_URL}/upload`;

  let formData = new FormData();
  formData.append("folderName", "faces");
  formData.append("file", payload);

  return axios.post(URL, formData).then((response) => {
    return response.data;
  });
};

export const registerStudentFaces = (payload) => {
  const token = localStorage.getItem("access_token");

  let URL = `${process.env.REACT_APP_API_URL}/Attendance/register-faces`;
  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payload),
  }).then((response) => {
    return response.data;
  });
};

export const verifyFaceAndMarkAttendance = (payload) => {
  const token = localStorage.getItem("access_token");

  let URL = `${process.env.REACT_APP_API_URL}/Attendance/verify-and-mark-attendance`;
  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payload),
  }).then((response) => {
    return response.data;
  });
};

export const markAttendanceWithStudentID = (payload) => {
  const token = localStorage.getItem("access_token");

  let URL = `${process.env.REACT_APP_API_URL}/Attendance/mark-attendance-using-id`;
  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(payload),
  }).then((response) => {
    return response.data;
  });
};
