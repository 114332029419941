export const getMonthList = () => {
  const monthList = [];
  let currentDate = new Date();
  currentDate.setDate(1);
  for (let i = 0; i < 12; i++) {
    let prev_month = currentDate.toLocaleDateString("default", {
      month: "long",
    });
    monthList.push({ date: prev_month, m_number: currentDate.getMonth() + 1 });
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  return monthList;
};
