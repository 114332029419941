export const SAVE_SHARED_SESSION_LISTING = "SAVE_SHARED_SESSION_LISTING";
export const SAVE_SHARED_SESSION_SEARCH_LISTING =
  "SAVE_SHARED_SESSION_SEARCH_LISTING";
export const SAVE_SHARED_SESSION_SEARCH_STRING =
  "SAVE_SHARED_SESSION_SEARCH_STRING";
export const SET_SHARED_SESSION_FILTER_SIDEBAR_VISIBILITY =
  "SET_SHARED_SESSION_FILTER_SIDEBAR_VISIBILITY";
export const SAVE_FILTERED_SHARED_SESSION_LISTING =
  "SAVE_FILTERED_SHARED_SESSION_LISTING";
export const SET_SHARED_SESSION_FILTERS = "SET_SHARED_SESSION_FILTERS";
export const SET_LISTING_LIMIT = "SET_LISTING_LIMIT";
export const CLEAR_SHARED_SESSION_LISTING = "CLEAR_SHARED_SESSION_LISTING";
export const SET_CHECKED_ITEMS = "SET_CHECKED_ITEMS";
