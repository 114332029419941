import * as Constants from '../../constants/dashboard.constant'
import * as dashboardService from '../../services/dashboard/dashboard.service'
import * as Helper from '../../../utility/context/helper'
export const bellNotificationAction = (payload) =>{
    return dispatch =>{

        dashboardService.bellNotification()
            .then(response=>{
             dispatch({
                 type:Constants.SAVE_BELL_NOTIFICATION,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }

export const classDetailsAction = (payload) =>{
    return dispatch =>{

        dashboardService.classDetails()
            .then(response=>{
             dispatch({
                 type:Constants.SAVE_CLASS_DETAILS,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }

 export const markReadBellNotificationAction = (payload) =>{
    return dispatch =>{

        dashboardService.markReadBellNotification(payload)
            .then(response=>{
             dispatch({
                 type:Constants.SAVE_BELL_NOTIFICATION,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }

 export const studentDashboardData = (payload) =>{
    return dispatch =>{

        dashboardService.fetchStudentDashboardStats(payload)
            .then(response=>{
             dispatch({
                 type:Constants.SAVE_STUDENT_DASHBOARD,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }
 export const teacherDashboardData = (payload) =>{
    return dispatch =>{

        dashboardService.fetchTeacherDashboardStats(payload)
            .then(response=>{
             dispatch({
                 type:Constants.SAVE_TEACHER_DASHBOARD,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }


 export const dashboardGraph = (payload) =>{
    return dispatch =>{

        dashboardService.fetchTeacherDashboardGraph(payload)
            .then(response=>{
             dispatch({
                 type:Constants.GET_DASHBOARD_GRAPH_DATA,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }

 export const setMonthList = () =>{
    return dispatch =>{
             dispatch({
                 type:Constants.SET_MONTH_LIST,
                 payload: Helper.getMonthList()
             })
     }
 }

 export const teacherDashboardGraphDetails = (payload) =>{
    return dispatch =>{

        dashboardService.fetchTeacherDashboardGraph(payload)
            .then(response=>{
             dispatch({
                 type:Constants.GET_DASHBOARD_GRAPH_DATA,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }