import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_FAIL,
  USER_LOGOUT_SUCCESS,
  UPDATE_TOKEN,
  SET_USER_PROFILE_PAGE,
  FETCH_USER_PROFILE_PAGE,
  FAILURE_FETCH_PROFILE_PAGE_DATA,
} from "../../constants/user.constant";
import {
  login,
  forgotPassword,
  resetPassword,
  updateProfilePicture,
  fetchProfilePageData,
} from "../../services/usersServices/user.service";
import { history } from "../../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SecureLS from "secure-ls";
toast.configure();

const SecureLocalstorage = new SecureLS();
export const loginAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    return login(payload)
      .then((response) => {
        if (payload.remember && payload.email !== "") {
          localStorage.username = payload.email;
          // localStorage.password = password
          SecureLocalstorage.set("password", payload.password);
          localStorage.remember = payload.remember;
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("remember");
          SecureLocalstorage.remove("password");
        }
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: response.data,
        });
        localStorage.setItem("access_token", response.data.token);
        toast.success(response.message);
        history.push("/");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        localStorage.setItem("access_token", null);
        dispatch({
          type: USER_LOGIN_FAIL,
        });

        // history.push("/login")
      });
  };
};
export const logoutAction = (payload) => {
  return (dispatch) => {
    localStorage.setItem("access_token", null);
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
    history.push("/login");
  };
};

export const forgotPasswordAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });
    return forgotPassword(payload).then((response) => {
      dispatch({
        type: USER_FORGOT_PASSWORD_SUCCESS,
        payload: response.data,
      });
      toast.success(response.message);
    });
  };
};

export const resetPasswordAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });
    return resetPassword(payload)
      .then((response) => {
        dispatch({
          type: USER_RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
        localStorage.setItem("access_token", response.data.access_token);
        toast.success("Password Reset Successfully");
        history.push("/login");
        localStorage.setItem("otp", null);
      })
      .catch((error) => {
        toast.error("OTP not verified");
        dispatch({
          type: USER_RESET_PASSWORD_FAIL,
        });
        // history.push("/dashboard")
      });
  };
};

export const updateProfilePictureAction = (payload) => {
  return (dispatch) => {
    return updateProfilePicture(payload);
  };
};
export const setUserToStore = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TOKEN,
      payload: payload,
    });
  };
};
export const fecthUserProfilePage = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_PROFILE_PAGE,
    });
    fetchProfilePageData()
      .then((response) => {
        dispatch({
          type: SET_USER_PROFILE_PAGE,
          payload: response,
        });
      })
      .catch(
        dispatch({
          type: FAILURE_FETCH_PROFILE_PAGE_DATA,
        })
      );
  };
};
