import * as constants from '../../constants'


export const setNotificationAvailableStatus = (payload) => {
    return dispatch => {
            dispatch({ type: constants.SET_NOTIFICATION_AVAILABLE_STATUS, payload: payload })
    }
}

export const updateUnreadNotificationCounter = (payload) => {
    return dispatch => {
            dispatch({ type: constants.TOTAL_UNREAD_NOTIFICATION, payload: payload })
    }
}