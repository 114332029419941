import axios from "axios";

export const searchListing = (args) => {
  let URL = `${process.env.REACT_APP_API_URL}/Attendance/search-student-attendance-report?subject_name=${args}`;
  const token = localStorage.getItem("access_token");

  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "GET",
    headers: headers,
  }).then((response) => {
    return response.data;
  });
};

export const filteredListing = (args) => {
  let URL = `${process.env.REACT_APP_API_URL}/Attendance/student-attendance-report-subject-wise`;

  const token = localStorage.getItem("access_token");
  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: args,
  }).then((response) => {
    return response.data;
  });
};

export const monthlyAttendanceReportStudentForSubject = (args) => {
  let URL = `${process.env.REACT_APP_API_URL}/Attendance/student-attendance-report-subject-wise`;

  const token = localStorage.getItem("access_token");
  const headers = {
    "content-Type": "application/json",
    token: token,
  };
  return axios({
    url: URL,
    method: "POST",
    headers: headers,
    data: JSON.stringify(args),
  }).then((response) => {
    return response.data.data;
  });
};
