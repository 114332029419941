
export const SAVE_SEARCH_LISTING_STUDENT = 'SAVE_SEARCH_LISTING_STUDENT'
export const SAVE_SEARCH_STRING_STUDENT = 'SAVE_SEARCH_STRING_STUDENT'
export const SET_LISTING_LIMIT_STUDENT = 'SET_LISTING_LIMIT_STUDENT'
export const SAVE_CLASS_DETAILS = 'SAVE_CLASS_DETAILS'
export const SET_PROFILE_SIDEBAR_VISIBILITY_STUDENT = 'SET_PROFILE_SIDEBAR_VISIBILITY_STUDENT'

export const SET_ACTIVE_PROFILE_STUDENT = 'SET_ACTIVE_PROFILE_STUDENT'
export const CLEAR_LISTING_STUDENT = 'CLEAR_LISTING_STUDENT'
export const SET_FILTER_SIDEBAR_VISIBILITY_STUDENT= 'SET_FILTER_SIDEBAR_VISIBILITY_STUDENT'
export const SET_FILTERS_STUDENT = 'SET_FILTERS_STUDENT'
export const SAVE_FILTERED_LISTING_STUDENT = 'SAVE_FILTERED_LISTING_STUDENT'
