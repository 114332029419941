import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import customizer from "./customizer/"
import auth from "./auth/"
import dataList from "./data-list/"
import {authenticate,forgotPassword,resetPassword,profilePage} from "./userReducers/user.reducer"
import{studentSession} from "./dashboard/studentDashboard.reducer"
import{Dashboard} from "./dashboard/dashboard.reducer"
import { reducer as formReducer } from 'redux-form'
import {sharedSessionReducer} from './sharedSession/sharedSession.reducer'
import {recordedSession} from './recordedSession/recordedSession.reducer'
import {notificationReducer} from './notification/notification.reducer'
import {attendanceManagementStudent} from './attendanceManagement/attendanceManagementStudentReducer'
import {attendanceManagementTeacher} from './attendanceManagement/attendanceManagementTeacherReducer'
import {liveSessionReducer} from './liveSession/liveSession.reducer'

const rootReducer = combineReducers({
  form: formReducer,
  calendar: calenderReducer,
  emailApp: emailReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  dataList: dataList,
  authenticate: authenticate,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  profilePage:profilePage,
  studentSession:studentSession,
  dashboard : Dashboard,
  sharedSession :sharedSessionReducer,
  recordedSession,
  notifications: notificationReducer,
  attendanceManagementStudent:attendanceManagementStudent,
  attendanceManagementTeacher:attendanceManagementTeacher,
  liveSessionReducer:liveSessionReducer,

})

export default rootReducer
