export  const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"


export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS"

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST"
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_RFORGOT_PASSWORD_SUCCESS"
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL"

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST"
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS"
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL"

export const UPDATE_TOKEN ="UPDATE_TOKEN"
export const FETCH_USER_PROFILE_PAGE="FETCH_USER_PROFILE_PAGE"
export const SET_USER_PROFILE_PAGE  = "SET_USER_PROFILE_PAGE"
export const FAILURE_FETCH_PROFILE_PAGE_DATA="FAILURE_FETCH_PROFILE_PAGE_DATA"