import * as constants from "../../constants/sharedSession/sharedSession.constant";
const initialState = {
  sharedSessionListing: {},
  activePage: 1,
  searchSharedSessionListing: [],
  searchString: "",
  limit: 10,
  total: 0,
  sharedSessionFilterSidebarVisibility: false,
  sharedSessionFilters: {},
  filteredSharedSessionListing: {},
  checkedItems: new Map(),
};
export const sharedSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_SHARED_SESSION_LISTING: {
      const oldListing = { ...state.sharedSessionListing };
      oldListing[action.payload.page] = action.payload;
      const activePage = action.payload.page;
      return { ...state, sharedSessionListing: oldListing, activePage };
    }
    case constants.SAVE_SHARED_SESSION_SEARCH_LISTING: {
      const oldListing = action.payload;
      // oldListing[action.payload.page] = action.payload
      // const activePage = action.payload.page
      return { ...state, searchSharedSessionListing: oldListing };
    }
    case constants.SAVE_SHARED_SESSION_SEARCH_STRING: {
      return { ...state, searchString: action.payload };
    }

    case constants.SET_SHARED_SESSION_FILTER_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sharedSessionFilterSidebarVisibility: action.payload,
      };
    case constants.SET_LISTING_LIMIT: {
      return { ...state, limit: action.payload };
    }
    case constants.SAVE_FILTERED_SHARED_SESSION_LISTING: {
      const oldListing = { ...state.filteredSharedSessionListing };
      oldListing[action.payload.page] = action.payload;
      const activePage = action.payload.page;
      const total = action.payload.total;
      return {
        ...state,
        filteredSharedSessionListing: oldListing,
        activePage,
        total,
      };
    }
    case constants.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.payload,
      };
    case constants.SET_SHARED_SESSION_FILTERS:
      return {
        ...state,
        sharedSessionFilters: action.payload,
      };
    case constants.CLEAR_SHARED_SESSION_LISTING:
      return {
        ...state,
        sharedSessionListing: {},
        filteredSharedSessionListing: {},
        activePage: 1,
        searchSharedSessionListing: [],
        checkedItems: new Map(),
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        sharedSessionListing: {},
        activePage: 1,
        searchSharedSessionListing: [],
        searchString: "",
        limit: 10,
        total: 0,
        sharedSessionFilterSidebarVisibility: false,
        sharedSessionFilters: {},
        filteredSharedSessionListing: {},
        checkedItems: new Map(),
      };

    default: {
      return state;
    }
  }
};
