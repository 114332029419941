import * as Constants from "../../constants/dashboard.constant";
import _ from "lodash";
let initialValues = {
  bellNotification: [],
  classDetails: {},
  total_unread: 0,
  teacherDashboardStats: {},
  studentDashboardStats: {},
};

export const Dashboard = (state = initialValues, action) => {
  switch (action.type) {
    case Constants.SAVE_BELL_NOTIFICATION:
      return {
        ...state,
        bellNotification: action.payload.bell_notifications,
        total_unread: action.payload.total_unread_notifications,
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        bellNotification: [],
        classDetails: {},
        total_unread: 0,
        teacherDashboardStats: {},
        studentDashboardStats: {},
      };

    case Constants.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload,
      };
    case Constants.SAVE_TEACHER_DASHBOARD:
      return {
        ...state,
        teacherDashboardStats: action.payload,
      };
    case Constants.SAVE_STUDENT_DASHBOARD:
      return {
        ...state,
        studentDashboardStats: action.payload,
      };
    case Constants.GET_DASHBOARD_GRAPH_DATA:
      const labels = [];
      const data = [];
      let { graph_data } = action.payload;
      graph_data = _.sortBy(graph_data, ["day"], ["asc"]);
      graph_data.map((item, index) => labels.push(item.day));
      graph_data.map((item) => data.push(item.duration));

      return {
        ...state,
        teacherDashboardGraph: { labels, data },
      };
    case Constants.SET_MONTH_LIST:
      return {
        ...state,
        month_list: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
