import * as constants from '../../constants/notifications.constant'
const initialState = {
    newNotificationAvaliable: false,
    total_unread :0
}
export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_NOTIFICATION_AVAILABLE_STATUS: {
            return { ...state, newNotificationAvaliable: action.payload.value,
                total_unread: action.payload.count
            }
        }
        case constants.TOTAL_UNREAD_NOTIFICATION: {

            return { ...state, total_unread: action.payload }
        }
        

        default: {
            return state
        }
    }
}
