export  const STUDENT_SESSION_REQUEST = "STUDENT_SESSION_REQUEST"
export const STUDENT_SESSION_SUCCESS = "STUDENT_SESSION_SUCCESS"
export const STUDENT_SESSION_FAIL = "STUDENT_SESSION_FAIL"
export const SAVE_BELL_NOTIFICATION = "SAVE_BELL_NOTIFICATION"
export const SAVE_CLASS_DETAILS = "SAVE_CLASS_DETAILS"
export const SAVE_STUDENT_DASHBOARD ="SAVE_STUDENT_DASHBOARD"
export const SAVE_TEACHER_DASHBOARD ="SAVE_TEACHER_DASHBOARD"
export const GET_DASHBOARD_GRAPH_DATA = "GET_DASHBOARD_GRAPH_DATA"
export const SET_MONTH_LIST = "SET_MONTH_LIST"

export const SET_STUDENT_DASHBOARD_REQUEST = "SET_STUDENT_DASHBOARD_REQUEST"
export const SET_STUDENT_DASHBOARD_STATS = "SET_STUDENT_DASHBOARD_STATS"