import * as constants from "../../constants";
let initialState = {
  currentSession: {},
};

export const liveSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CURRENT_SESSION_DETAIL: {
      return {
        ...state,
        currentSession: action.payload,
      };
    }
    case constants.LIVE_SESSION_OVER: {
      return {
        ...state,
        liveSessionOver: action.payload,
      };
    }
    case "USER_LOGOUT_SUCCESS":
      return {
        currentSession: {},
      };
    case constants.CLEAR_LIVE_SESSION_EVENT_DATA: {
      return {
        ...state,
        liveSessionOver: undefined,
      };
    }

    default:
      return state;
  }
};
