import { USER_LOGIN_REQUEST,USER_LOGIN_SUCCESS,USER_LOGIN_FAIL, USER_LOGOUT_SUCCESS,USER_FORGOT_PASSWORD_SUCCESS,USER_RESET_PASSWORD_REQUEST,USER_RESET_PASSWORD_SUCCESS,USER_RESET_PASSWORD_FAIL,UPDATE_TOKEN,SET_USER_PROFILE_PAGE,FETCH_USER_PROFILE_PAGE,FAILURE_FETCH_PROFILE_PAGE_DATA} from '../../constants/user.constant'
let initialValues = {
    login :{
        requesting: false,
        token: null,
        user: null,
    },
    resetPassword :{
        requesting: false,
        token: null,
        user: null
    },
}
let profilePageData = {
    profile:null,
    fetched : false,
}
export const authenticate = (state = initialValues, action) => {
    switch (action.type){
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                requesting: true
            }
        case USER_LOGIN_SUCCESS :
            return{
                ...state,
                login :{
                ...state.login,
                user:action.payload.user,
                token: action.payload.token,
                requesting: false
                }
            }
        case UPDATE_TOKEN :
            return {

                ...state,
                login :{
                    ...state.login,
                    user:action.payload.user,
                    token: action.payload.token,
                    requesting: false,
                }
            }
            case USER_LOGIN_FAIL:
            return {
                ...state,
                login :{
                    token: null,
                    user: null,
                    requesting: false
                }
            }
            case USER_LOGOUT_SUCCESS:
            return {
                ...state,
                login :{
                    requesting: false,
                    token: null,
                    user: null
                },
              
            }
            default:
            return {
                ...state
            }
    }
}

export const forgotPassword = (state = null, action) => {

    switch (action.type){

        case USER_FORGOT_PASSWORD_SUCCESS :
            return{
                ...state,
                email:action.payload.email,
            }

            default:
            return {
                ...state
            }
    }
}

export const resetPassword = (state = initialValues, action) => {

    switch (action.type){
        case USER_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                requesting: true

            }
        case USER_RESET_PASSWORD_SUCCESS :
            return{
                ...state,
                resetPassword :{
                user:action.payload,
                token: action.payload.token,
                requesting: false
                }
            }
            case USER_RESET_PASSWORD_FAIL:
            return {
                ...state,
                resetPassword :{
                    token: null,
                    user: null,
                    requesting: false
                }
            }
            default:
            return {
                ...state
            }
    }
}

export const profilePage = (state=profilePageData , action)=>{
    switch(action.type){
        case FETCH_USER_PROFILE_PAGE:
            return{
                ...state,
                fetched:false
            }

        case SET_USER_PROFILE_PAGE :
        return{
            ...state,
            profile:action.payload.data,
            fetched:true
        }
        case FAILURE_FETCH_PROFILE_PAGE_DATA:
            return{
                ...state,
                profile:null,
                fetched:false
            }
         default:
             return {...state}
    }


}
