import * as constants from "../../constants";

const initialState = {
  searchTeacherListing: [],
  searchString: "",
  subjects: [],
  classDetails: {},
  activeProfile: {},
  profileSidebarVisibility: false,
  attendaceChart: {},
  filterSidebarVisibility: false,
  filters: {},
  filteredListing: {},
  monthlyAttendance: {},
};

export const attendanceManagementStudent = (state = initialState, action) => {
  switch (action.type) {
    case constants.MONTHLY_ATTENDANCE_REPORT_STUDENT_FOR_SUBJECT: {
      return { ...state, monthlyAttendance: action.payload };
    }
    case constants.SAVE_SEARCH_LISTING_STUDENT: {
      const oldListing = action.payload;
      // oldListing[action.payload.page] = action.payload
      // const activePage = action.payload.page

      return {
        ...state,
        searchListing: oldListing,
      };
    }
    case constants.SAVE_SEARCH_STRING_STUDENT: {
      return { ...state, searchString: action.payload };
    }
    case constants.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload,
      };
    case constants.SET_ACTIVE_PROFILE_STUDENT: {
      return {
        ...state,
        activeProfile: action.payload,
      };
    }
    case constants.SET_PROFILE_SIDEBAR_VISIBILITY_STUDENT:
      return {
        ...state,
        profileSidebarVisibility: action.payload,
      };

    case constants.SET_FILTER_SIDEBAR_VISIBILITY_STUDENT:
      return {
        ...state,
        filterSidebarVisibility: action.payload,
      };

    case constants.SET_FILTERS_STUDENT: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case constants.CLEAR_LISTING_STUDENT: {
      return {
        ...state,
        filteredListing: {},
        searchListing: [],
        profileSidebarVisibility: false,
        filterSidebarVisibility: false,
        activeProfile: {},
        searchListing: [],
      };
    }
    case constants.SAVE_FILTERED_LISTING_STUDENT: {
      const oldListing = action.payload;
      let dataListing = action.payload && action.payload.data;
      let sub = [];
      dataListing.map((item) => {
        sub.push(item.subject_name);
      });

      return {
        ...state,
        filteredListing: oldListing,
        subjects: [...sub],
      };
    }
    case "USER_LOGOUT_SUCCESS":
      return {
        searchTeacherListing: [],
        searchString: "",
        subjects: [],
        classDetails: {},
        activeProfile: {},
        profileSidebarVisibility: false,
        attendaceChart: {},
        filterSidebarVisibility: false,
        filters: {},
        filteredListing: {},
      };
    default: {
      return state;
    }
  }
};
