import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";
import { history } from "../../../history";
import { logoutAction } from "../../../redux/actions/userActions/user.action";
import { connect } from "react-redux";
import {
  bellNotificationAction,
  markReadBellNotificationAction,
} from "../../../redux/actions/dashboard/dashboard.action";
import * as notificationActions from "../../../redux/actions/notifications/notification.action";
import moment from "moment";
import { toast } from "react-toastify";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const handleLogout = (e) => {
    e.preventDefault();
    props.dispatch(logoutAction());
  };

  const handleProfileNavigation = () => {
    let navRole = "";

    if (props.login !== undefined) {
      if (props.login.user.role !== undefined && props.login.user.role == 0)
        navRole = "/teacher-profile";
      else if (
        props.login.user.role !== undefined &&
        props.login.user.role == 1
      )
        navRole = "/student-profile";
      else navRole = "/";
    }

    return navRole;
  };
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href={handleProfileNavigation()}
        onClick={(e) => handleNavigation(e, handleProfileNavigation())}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" onClick={(e) => handleLogout(e)}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
  };
  componentDidMount = () => {
    this.handleNotifications();
  };
  handleNotifications = (e) => {
    this.props.dispatch(bellNotificationAction());
    this.props.dispatch(
      notificationActions.setNotificationAvailableStatus(false)
    );
  };

  getNotificationTime = (date) => {
    const currentTime = moment();
    const days = currentTime.diff(date, "days");
    const dayString = days ? `${days} days ` : "";
    const hours = currentTime.diff(date, "hours");
    const hourString = days ? "" : hours ? `${hours} hours ` : "";
    const minutes = currentTime.diff(date, "minutes");
    const minuteString =
      days || hours ? "" : minutes ? `${minutes} minutes ` : "";
    const seconds = currentTime.diff(date, "seconds");
    const secondString = days || hours || minutes ? "" : `${seconds} seconds `;
    return dayString + hourString + minuteString + secondString + "ago";
  };

  markAllReadNotification = () => {
    this.props.dispatch(markReadBellNotificationAction({ read_all: "true" }));
  };

  handleRedirection = (e, item) => {
    this.props.dispatch(
      markReadBellNotificationAction({ notification_id: item._id })
    );

    if (item.session_status == 2) {
      e.preventDefault();
      toast.error("class is over ");
      return false;
    } else {
      return true;
    }
  };
  render() {
    const {
      bellNotifications,
      newNotificationAvaliable,
      user,
      total_unread,
      socketCounter,
    } = this.props;
    const TopNotification = bellNotifications && bellNotifications[0];

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <li className="nav-item hidden-sm">
          {user && user.user && user.user.role == 0 && (
            <Link className="nav-link nav-link-label p-0 pt-10px" to="#">
              <button
                className=" btn btn-info bold"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/live-session");
                }}
              >
                <Icon.Video className="mr-1" size={16} />
                Live Session
              </button>
            </Link>
          )}
        </li>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle
            tag="a"
            className="nav-link nav-link-label"
            onClick={(e) => this.handleNotifications(e)}
          >
            <Icon.Bell size={21} />

            {newNotificationAvaliable && newNotificationAvaliable ? (
              <Badge pill color="primary" className="badge-up">
                {" "}
                {socketCounter}{" "}
              </Badge>
            ) : (
              <Badge pill color="primary" className="badge-up">
                {/* total unread should be shown */}
                {total_unread && total_unread ? total_unread : ""}
              </Badge>
            )}
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                {/* total unread should be shown */}
                <h3 className="text-white">{total_unread && total_unread}</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {bellNotifications &&
                bellNotifications.map((item) => {
                  return (
                    <a
                      href={`${
                        item.href
                          ? item.href.includes("None")
                            ? "#"
                            : item.href
                          : // .replace(
                            //     "https://vcafbeta778.hestawork.com",
                            //     "http://localhost:3000"
                            //   )
                            "#"
                      }`}
                      onClick={(e) => this.handleRedirection(e, item)}
                    >
                      {" "}
                      <div className="d-flex justify-content-between">
                        <Media className="d-flex align-items-start">
                          <Media left href="#">
                            <Icon.PlusSquare
                              className="font-medium-5 primary"
                              size={21}
                            />
                          </Media>
                          <Media body>
                            <Media
                              heading
                              className="primary media-heading"
                              tag="h6"
                            >
                              {item.title}
                            </Media>
                            <p className="notification-text">
                              {item.description}
                            </p>
                          </Media>
                          <small>
                            <time
                              className="media-meta"
                              dateTime="2015-06-11T18:29:20+08:00"
                            >
                              {this.getNotificationTime(item.createdAt)}
                            </time>
                            <Badge
                              pill
                              color={item.is_unread ? "success" : "primary"}
                              className="badge-down"
                            >
                              {" "}
                              *{" "}
                            </Badge>
                          </small>
                        </Media>
                      </div>
                    </a>
                  );
                })}
              {/* <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.DownloadCloud
                      className="font-medium-5 success"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="success media-heading" tag="h6">
                      99% Server load
                    </Media>
                    <p className="notification-text">
                      You got new order of goods?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      5 hours ago
                    </time>
                  </small>
                </Media>
              </div> */}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span
                  className="align-middle"
                  onClick={() => this.markAllReadNotification()}
                >
                  Read all notifications
                </span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name mb-0">{this.props.userName}!</span>
            </div>
            <span data-tour="user" className="user-image">
              <img
                src={this.props.userImg}
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} role={this.props.login} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.authenticate.login,
    bellNotifications: state.dashboard.bellNotification,
    newNotificationAvaliable: state.notifications.newNotificationAvaliable,
    socketCounter: state.notifications.total_unread,
    total_unread: state.dashboard.total_unread,
  };
};

export default connect(mapStateToProps)(NavbarUser);
