import {
  STUDENT_SESSION_REQUEST,
  STUDENT_SESSION_SUCCESS,
  STUDENT_SESSION_FAIL,
} from "../../constants/dashboard.constant";
let initialValues = {
  sessionDetails: {
    requesting: false,
    session: null,
  },
};

export const studentSession = (state = initialValues, action) => {
  switch (action.type) {
    case STUDENT_SESSION_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case STUDENT_SESSION_SUCCESS:
      return {
        ...state,
        sessionDetails: {
          ...state.sessionDetails,
          session: action.payload,
          requesting: false,
        },
      };
    case STUDENT_SESSION_FAIL:
      return {
        ...state,
        sessionDetails: {
          session: null,
          requesting: false,
        },
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        sessionDetails: {
          requesting: false,
          session: null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
