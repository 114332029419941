import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import brand_logo from "../../../../assets/img/logo/UPPS.png"

class SidebarHeader extends Component {
  render() {
    return (
      <div className="navbar-headers p-1">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="navbar-brand">
              <img src={brand_logo} width="150" alt="brand_logo"/>
            </NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default SidebarHeader
