export const SAVE_SEARCH_LISTING_TEACHER = "SAVE_SEARCH_LISTING_TEACHER";
export const SAVE_SEARCH_STRING_TEACHER = "SAVE_SEARCH_STRING_TEACHER";
export const SET_LISTING_LIMIT_TEACHER = "SET_LISTING_LIMIT_TEACHER";
export const SAVE_CLASS_DETAILS = "SAVE_CLASS_DETAILS";
export const SET_PROFILE_SIDEBAR_VISIBILITY_TEACHER =
  "SET_PROFILE_SIDEBAR_VISIBILITY_TEACHER";

export const SET_ACTIVE_PROFILE_TEACHER = "SET_ACTIVE_PROFILE_TEACHER";
export const CLEAR_LISTING_TEACHER = "CLEAR_LISTING_TEACHER";
export const SET_CHECKED_ITEMS_TEACHER = "SET_CHECKED_ITEMS_TEACHER";
export const SET_FILTER_SIDEBAR_VISIBILITY_TEACHER =
  "SET_FILTER_SIDEBAR_VISIBILITY_TEACHER";
export const SET_FILTERS_TEACHER = "SET_FILTERS_TEACHER";
export const SAVE_FILTERED_LISTING_TEACHER = "SAVE_FILTERED_LISTING_TEACHER";
export const SAVE_ATTENDANCE_CHART_TEACHER = "SAVE_ATTENDANCE_CHART_TEACHER";
export const SET_ATTENDANCE_SELECTOR = "SET_ATTENDANCE_SELECTOR";
export const MONTHLY_ATTENDANCE_REPORT_STUDENT =
  "MONTHLY_ATTENDANCE_REPORT_STUDENT";
export const MONTHLY_ATTENDANCE_REPORT_STUDENT_FOR_SUBJECT =
  "MONTHLY_ATTENDANCE_REPORT_STUDENT_FOR_SUBJECT";
