import React from "react"
import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"

import {fecthUserProfilePage} from '../../../redux/actions/userActions/user.action'
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/user_pic.jpg"
import {useEffect} from 'react';
import { useDispatch} from 'react-redux';
import AlertDismissable from '../../../components/reactstrap/alerts/AlertDismissable'
import {useLocation } from 'react-router-dom';

const UserName = props => {
  let username =  "Hello Bran !"


  return username
}
const titleCase =(str)=> {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}
const ThemeNavbar = props => {
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const {login}=props
  useEffect(() => {
    dispatch(props.fecthUserProfilePage())
    },[dispatch])
  const location = useLocation()

let navbarHeading=location.pathname.replace("-"," ").substring(1)
const navbarTitle = titleCase(navbarHeading);



  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <React.Fragment>
    {login && login.user && login.user.role ==1 ? navbarTitle ==="DASHBOARD" &&  <AlertDismissable /> : null}
    {login && login.user && login.user.role ==1 ? navbarTitle ==="DASHBOARD" &&  <div className="dashboard-head"><AlertDismissable /></div> : null} 
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow navbar-light floating-nav navbar"
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <h5 className="main-head-heading">{navbarTitle ? navbarTitle=="Dashboard"?"DASHBOARD":navbarTitle:"DASHBOARD"}</h5>
              </div>
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                login ={props.login}
                userName={props.profile && props.profile.first_name && 
                  props.profile.first_name?"Hello " + props.profile.first_name:""
                }
                // {<UserName userdata={user} {...props}   />}
                userImg={ props.profile?.profile_picture || userImg }
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth,
    login : state.authenticate.login,
    profile:state.profilePage.profile
        
  }
}
const mapDispatchToProps = dispatch => {
  return {
    useAuth0,
    fecthUserProfilePage:()=>dispatch(fecthUserProfilePage)
   
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(ThemeNavbar)
