import React from "react"
import {Alert} from "reactstrap"
import {Link} from 'react-router-dom'

class AlertDismissable extends React.Component {
  state = {
    visible: true
  }
  dismissAlert = () => {
    this.setState({ visible: false })
  }

  render() {
    return (
      <React.Fragment>
        <div>
        <Alert
            className="custom-alert-dashboard"
            isOpen={this.state.visible}
            toggle={this.dismissAlert}
          >
            It seems you have not added your face to the application please go to the <Link to="/student-profile">“Profile”</Link> section and click on
              <Link to="/record-face"> “Register your face”</Link> to for quicker access to your Live sessions 
          </Alert>
        </div>
      </React.Fragment>
    )
  }
}
export default AlertDismissable
